import { Space } from "antd";
import {
  GUA,
  SHENGKE_NAME,
  WANGSHUA as WANGSHUAI,
  WANGSHUA_NAME as WANGSHUAI_NAME,
  ZHI,
} from "./CONSTANTS";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  SwapOutlined,
  VerticalAlignMiddleOutlined,
} from "@ant-design/icons";

export function getGuaIndexByYao(yao) {
  let index;

  if (!yao) {
    return index;
  }

  GUA.some((gua, idx) => {
    if (gua && +yao.join("") === +gua.yao.join("")) {
      index = idx;

      return true;
    }

    return false;
  });

  return index;
}

export function getGuaByYao(yao) {
  let gua;

  if (!yao) {
    return gua;
  }

  GUA.some((g) => {
    if (gua && +yao.join("") === +gua.yao.join("")) {
      gua = g;

      return true;
    }

    return false;
  });

  return gua;
}

export function getShengke(tiGuaIndex, yongGuaIndex, yueZhi) {
  const ti = GUA[tiGuaIndex];
  const yong = GUA[yongGuaIndex];
  const yueLingIndex = ZHI.indexOf(yueZhi);
  let subjectWS;
  let objectWS;
  let subject = "体";
  let object = "用";
  let skIdx = -1;

  ti.shengke.some((item, idx) => {
    if (item.includes(yong.name)) {
      skIdx = idx;
      subjectWS = WANGSHUAI_NAME[WANGSHUAI[yueLingIndex].indexOf(ti.wuxing)];
      objectWS = WANGSHUAI_NAME[WANGSHUAI[yueLingIndex].indexOf(yong.wuxing)];

      return true;
    }

    return false;
  });

  if (skIdx < 0) {
    subject = "用";
    object = "体";
    subjectWS = WANGSHUAI_NAME[WANGSHUAI[yueLingIndex].indexOf(yong.wuxing)];
    objectWS = WANGSHUAI_NAME[WANGSHUAI[yueLingIndex].indexOf(ti.wuxing)];
    yong.shengke.some((item, idx) => {
      if (item.includes(ti.name)) {
        skIdx = idx;

        return true;
      }

      return false;
    });
  }

  return (
    <Space>
      <span>
        {subjectWS}
        {subject}
      </span>
      <span className="shengke-name">{SHENGKE_NAME[skIdx]}</span>
      <span>
        {objectWS}
        {object}
      </span>
    </Space>
  );
}

export function getArrow(shangGuaIndex, xiaGuaIndex) {
  const ti = GUA[shangGuaIndex];
  const yong = GUA[xiaGuaIndex];
  let skIdx = -1;

  ti.shengke.some((item, idx) => {
    if (item.includes(yong.name)) {
      skIdx = idx;

      return true;
    }

    return false;
  });

  if (skIdx === 0) {
    return (
      <div style={{ color: "darkgreen" }}>
        <ArrowDownOutlined />
      </div>
    );
  }

  if (skIdx === 1) {
    return (
      <div style={{ color: "#cc0000" }}>
        <ArrowDownOutlined />
      </div>
    );
  }

  if (skIdx < 0) {
    yong.shengke.some((item, idx) => {
      if (item.includes(ti.name)) {
        skIdx = idx;

        return true;
      }

      return false;
    });
  }

  if (skIdx === 0) {
    return (
      <div style={{ color: "darkgreen" }}>
        <ArrowUpOutlined />
      </div>
    );
  }

  if (skIdx === 1) {
    return (
      <div style={{ color: "#cc0000" }}>
        <ArrowUpOutlined />
      </div>
    );
  }

  if (skIdx === 2) {
    return (
      <div style={{ color: "#cc0000" }}>
        <VerticalAlignMiddleOutlined />
      </div>
    );
  }

  return (
    <div style={{ color: "darkgreen", transform: "rotate(90deg)" }}>
      <SwapOutlined />
    </div>
  );
}

export function isCool(guaIndex, yueZhi) {
  const gua = GUA[guaIndex];

  return gua.cool === yueZhi;
}

export function isKong(guaIndex, kong) {
  return kong.split("").some((item) => GUA[guaIndex].kong?.includes(item));
}

export function getShensha(guaIndex, shensha) {
  const gua = Array.isArray(guaIndex) ? GUA[getGuaIndexByYao(guaIndex)] : GUA[guaIndex];

  const result = [];
  
  if (!gua) {
    return result;
  }

  if (shensha?.rilu && gua.zhi.includes(shensha.rilu)) {
    result.push('日禄');
  }

  if (shensha?.yima && gua.zhi.includes(shensha.yima)) {
    result.push('驿马');
  }

  if (shensha?.taohua && gua.zhi.includes(shensha.taohua)) {
    result.push('桃花');
  }

  if (shensha?.guiren && gua.zhi.find(g => shensha.guiren.includes(g))) {
    result.push('贵人');
  }

  return result;
}
