import { useRecoilValue } from "recoil";
import {
  $bottomAtom,
  $kongAtom,
  $kuAtom,
  $timeAtom,
  $topAtom,
  $variableAtom,
  $wangShuaiAtom,
} from "../utils/state";
import { Space } from "antd";
import { Gua } from "./Gua";
import { BAGUA, GUA, SHENSHA } from "../utils/CONSTANTS";
import {
  getArrow,
  getGuaIndexByYao,
  getShengke,
  getShensha,
  isCool,
  isKong,
} from "../utils/tool";
import { TimeTable } from "./TimeTable";
import { useMemo } from "react";
import { Solar } from "lunar-typescript";

export const MeiHua = ({ reset }) => {
  const top = useRecoilValue($topAtom);
  const bottom = useRecoilValue($bottomAtom);
  const variable = useRecoilValue($variableAtom);
  const time = useRecoilValue($timeAtom);
  const ku = useRecoilValue($kuAtom);
  const kong = useRecoilValue($kongAtom);
  const wangshuai = useRecoilValue($wangShuaiAtom);

  const benGuaYao = GUA[top].yao.concat(GUA[bottom].yao);

  const huGuaYaoTop = benGuaYao.slice(1, 4);
  const huGuaYaoBottom = benGuaYao.slice(2, 5);
  const bianGuaYao = [].concat(benGuaYao).reverse();

  bianGuaYao[variable - 1] = +!bianGuaYao[variable - 1];

  const reversedBianGuaYao = bianGuaYao.reverse();
  const bianGuaYaoTop = reversedBianGuaYao.slice(0, 3);
  const bianGuaYaoBottom = reversedBianGuaYao.slice(3);

  const shensha = useMemo(() => {
    if (!time) {
      return;
    }

    const s = Solar.fromDate(time);

    const gan = s.getLunar().getDayGanExact2();
    const zhi = s.getLunar().getDayZhiExact2();

    const rilu = SHENSHA.日禄[gan];
    const guiren = SHENSHA.贵人[gan];
    const yima = SHENSHA.驿马[zhi];
    const taohua = SHENSHA.桃花[zhi];

    return { rilu, guiren, yima, taohua };
  }, [time]);

  const wangShuaiZhi = useMemo(() => {
    if (!time) {
      return;
    }

    const s = Solar.fromDate(time);

    switch (wangshuai) {
      case "yearly": {
        return s.getLunar().getYearZhiExact();
      }
      case "monthly": {
        return s.getLunar().getMonthZhiExact();
      }
      case "daily": {
        return s.getLunar().getDayZhiExact();
      }
      case "hourly": {
        return s.getLunar().getTimeZhi();
      }
      default: {
        return;
      }
    }
  }, [time, wangshuai]);

  const kuZhi = useMemo(() => {
    if (!time) {
      return;
    }

    const s = Solar.fromDate(time);

    switch (ku) {
      case "yearly": {
        return s.getLunar().getYearZhiExact();
      }
      case "monthly": {
        return s.getLunar().getMonthZhiExact();
      }
      case "daily": {
        return s.getLunar().getDayZhiExact();
      }
      case "hourly": {
        return s.getLunar().getTimeZhi();
      }
      default: {
        return;
      }
    }
  }, [time, ku]);

  const xunkong = useMemo(() => {
    if (!time) {
      return;
    }

    const s = Solar.fromDate(time);

    switch (kong) {
      case "yearly": {
        return s.getLunar().getYearXunKong();
      }
      case "monthly": {
        return s.getLunar().getMonthXunKong();
      }
      case "daily": {
        return s.getLunar().getDayXunKongExact();
      }
      case "hourly": {
        return s.getLunar().getTimeXunKong();
      }
      default: {
        return;
      }
    }
  }, [time, kong]);
  
  return (
    <div className="meihua">
      <TimeTable reset={reset} />
      <div className="guaxiang">
        <Space>
          <Space direction="vertical" size={10}>
            <h3>&nbsp;</h3>
            <span className="gua-name">&nbsp;</span>
            <div className="tiyong">{variable > 3 ? "用" : "体"}</div>
            <div>&nbsp;</div>
            <div className="tiyong">{variable > 3 ? "体" : "用"}</div>
            <h3 className="shengke">&nbsp;</h3>
          </Space>
          <Space direction="vertical" size={10}>
            <h3>本卦</h3>
            <span className="gua-name">{BAGUA[+benGuaYao.join("")]}</span>
            <div className="gua-container">
              <Gua
                idx={top}
                variableIndex={variable > 3 ? variable - 3 : undefined}
              />
              <div className="cool">
                {isCool(top, kuZhi) && "库"}
                {isKong(top, xunkong) && "空"}
              </div>
              <div className="shensha">
                {getShensha(top, shensha).map(item => <span>{item}</span>)}
              </div>
            </div>
            <div className="shengke-icon">{getArrow(top, bottom)}</div>
            <div className="gua-container">
              <Gua
                idx={bottom}
                variableIndex={variable <= 3 ? variable : undefined}
              />
              <div className="cool">
                {isCool(bottom, kuZhi) && "库"}
                {isKong(bottom, xunkong) && "空"}
              </div>
              <div className="shensha">
                {getShensha(bottom, shensha).map(item => <span>{item}</span>)}
              </div>
            </div>
            <h3 className="shengke">
              {getShengke(
                variable > 3 ? bottom : top,
                variable > 3 ? top : bottom,
                wangShuaiZhi
              )}
            </h3>
          </Space>
          <Space direction="vertical" size={10}>
            <h3>互卦</h3>
            <span className="gua-name">
              {BAGUA[+huGuaYaoTop.concat(huGuaYaoBottom).join("")]}
            </span>
            <div className="gua-container">
              <Gua idx={getGuaIndexByYao(huGuaYaoTop)} />
              <div className="cool">
                {isCool(getGuaIndexByYao(huGuaYaoTop), kuZhi) && "库"}
                {isKong(getGuaIndexByYao(huGuaYaoTop), xunkong) && "空"}
              </div>
              <div className="shensha">
                {getShensha(huGuaYaoTop, shensha).map(item => <span>{item}</span>)}
              </div>
            </div>
            <div className="shengke-icon">
              {getArrow(
                getGuaIndexByYao(huGuaYaoTop),
                getGuaIndexByYao(huGuaYaoBottom)
              )}
            </div>
            <div className="gua-container">
              <Gua idx={getGuaIndexByYao(huGuaYaoBottom)} />
              <div className="cool">
                {isCool(getGuaIndexByYao(huGuaYaoBottom), kuZhi) && "库"}
                {isKong(getGuaIndexByYao(huGuaYaoBottom), xunkong) && "空"}
              </div>
              <div className="shensha">
                {getShensha(huGuaYaoBottom, shensha).map(item => <span>{item}</span>)}
              </div>
            </div>
            <h3 className="shengke">
              {getShengke(
                variable > 3
                  ? getGuaIndexByYao(huGuaYaoBottom)
                  : getGuaIndexByYao(huGuaYaoTop),
                variable > 3
                  ? getGuaIndexByYao(huGuaYaoTop)
                  : getGuaIndexByYao(huGuaYaoBottom),
                wangShuaiZhi
              )}
            </h3>
          </Space>
          <Space direction="vertical" size={10}>
            <h3>变卦</h3>
            <span className="gua-name">
              {BAGUA[+reversedBianGuaYao.join("")]}
            </span>
            <div className="gua-container">
              <Gua idx={getGuaIndexByYao(bianGuaYaoTop)} />
              <div className="cool">
                {isCool(getGuaIndexByYao(bianGuaYaoTop), kuZhi) && "库"}
                {isKong(getGuaIndexByYao(bianGuaYaoTop), xunkong) && "空"}
              </div>
              <div className="shensha">
                {getShensha(bianGuaYaoTop, shensha).map(item => <span>{item}</span>)}
              </div>
            </div>
            <div className="shengke-icon">
              {getArrow(
                getGuaIndexByYao(bianGuaYaoTop),
                getGuaIndexByYao(bianGuaYaoBottom)
              )}
            </div>
            <div className="gua-container">
              <Gua idx={getGuaIndexByYao(bianGuaYaoBottom)} />
              <div className="cool">
                {isCool(getGuaIndexByYao(bianGuaYaoBottom), kuZhi) && "库"}
                {isKong(getGuaIndexByYao(bianGuaYaoBottom), xunkong) && "空"}
              </div>
              <div className="shensha">
                {getShensha(bianGuaYaoBottom, shensha).map(item => <span>{item}</span>)}
              </div>
            </div>
            <h3 className="shengke">
              {getShengke(
                variable > 3
                  ? getGuaIndexByYao(bianGuaYaoBottom)
                  : getGuaIndexByYao(bianGuaYaoTop),
                variable > 3
                  ? getGuaIndexByYao(bianGuaYaoTop)
                  : getGuaIndexByYao(bianGuaYaoBottom),
                wangShuaiZhi
              )}
            </h3>
          </Space>
        </Space>
      </div>
    </div>
  );
};
