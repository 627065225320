import { Badge } from "antd";
import { GUA } from "../utils/CONSTANTS";
import { Yao } from "./Yao";

export const Gua = ({ idx, variableIndex }) => {
  const gua = GUA[idx];

  if (!gua) {
    return null;
  }

  return (
    <div className="gua">
      {gua.yao.map((item, index) => (
        <div key={index} style={{ position: "relative" }}>
          <Yao yinyang={item} color={gua.color} />
          {variableIndex != undefined && variableIndex === (3 - index) && (
            <div
              className="variable"
            
            >
              <Badge status="processing" />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
