export const Yao = ({ yinyang, color }) => {
  const style = {};

  color && (style.backgroundColor = color);

  if (Number.isNaN(+yinyang)) {
    return null;
  }

  if (yinyang === 0) {
    return (
      <div className="yao yin">
        <div className="yao-item" style={style} />
        <div className="yao-item" style={style} />
      </div>
    );
  }

  return (
    <div className="yao yang">
      <div className="yao-item" style={style} />
    </div>
  );
};
