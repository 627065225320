import { useMemo, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  $kongAtom,
  $kuAtom,
  $numbersAtom,
  $thingAtom,
  $timeAtom,
  $wangShuaiAtom,
} from "../utils/state";
import { Solar } from "lunar-typescript";
import { Button, Space, Typography } from "antd";
import { KONGWANG } from "../utils/CONSTANTS";

const { Paragraph } = Typography;

export const TimeTable = ({ reset }) => {
  const numbers = useRecoilValue($numbersAtom);
  const time = useRecoilValue($timeAtom);
  const [ku, setKu] = useRecoilState($kuAtom);
  const [kong, setKong] = useRecoilState($kongAtom);
  const [wangshuai, setWangshuai] = useRecoilState($wangShuaiAtom);
  const [thing, setThing] = useRecoilState($thingAtom);
  const [prevJieQi, setPrevJieQi] = useState("");
  const [nextJieQi, setNextJieQi] = useState("");

  const lunar = useMemo(() => {
    if (time) {
      const solar = Solar.fromDate(time);
      const l = solar.getLunar();

      const prev = l.getPrevJieQi();
      setPrevJieQi(prev.getName() + " " + prev.getSolar().toYmdHms());
      const next = l.getNextJieQi();
      setNextJieQi(next.getName() + " " + next.getSolar().toYmdHms());

      return l;
    }

    return undefined;
  }, [time]);

  return (
    <table className="meihua-table">
      <tbody>
        <tr>
          <td>事由</td>
          <td colSpan={4}>
            <Space>
              <Paragraph
                style={{ margin: 0 }}
                editable={{ onChange: setThing }}
              >
                {thing}
              </Paragraph>
            </Space>
          </td>
        </tr>
        <tr>
          <td width={36}>{numbers ? "报数" : "方式"}</td>
          <td colSpan={4}>
            {numbers || "手动指定"}{" "}
            <Button onClick={reset} size="small" type="primary">
              重新起卦
            </Button>
          </td>
        </tr>
        <tr>
          <td>时间</td>
          <td colSpan={4}>{time?.toLocaleString()}</td>
        </tr>
        <tr>
          <td rowSpan={2}>节气</td>
          <td colSpan={4}>{prevJieQi}</td>
        </tr>
        <tr>
          <td colSpan={4}>{nextJieQi}</td>
        </tr>
        <tr>
          <td rowSpan={2}>时间</td>
          <td
            className={wangshuai === "yearly" ? "active" : ""}
            onClick={() => setWangshuai("yearly")}
          >
            年
          </td>
          <td
            className={wangshuai === "monthly" ? "active" : ""}
            onClick={() => setWangshuai("monthly")}
          >
            月
          </td>
          <td
            className={wangshuai === "daily" ? "active" : ""}
            onClick={() => setWangshuai("daily")}
          >
            日
          </td>
          <td
            className={wangshuai === "hourly" ? "active" : ""}
            onClick={() => setWangshuai("hourly")}
          >
            时
          </td>
        </tr>
        <tr>
          <td
            className={ku === "yearly" ? "nostyle active" : "nostyle"}
            onClick={() => setKu("yearly")}
          >
            <strong>{lunar?.getYearInGanZhiExact()}</strong>
          </td>
          <td
            className={ku === "monthly" ? "active" : ""}
            onClick={() => setKu("monthly")}
          >
            <strong style={{ color: "rgb(185 26 6)" }}>
              {lunar?.getMonthInGanZhiExact()}
            </strong>
          </td>
          <td
            className={ku === "daily" ? "active" : ""}
            onClick={() => setKu("daily")}
          >
            <strong style={{ color: "rgb(185 26 6)" }}>
              {lunar?.getDayInGanZhiExact2()}
            </strong>
          </td>
          <td
            className={ku === "hourly" ? "active" : ""}
            onClick={() => setKu("hourly")}
          >
            <strong>{lunar?.getTimeInGanZhi()}</strong>
          </td>
        </tr>
        <tr>
          <td rowSpan={2}>空亡</td>
          <td
            className={kong === "yearly" ? "gray-text active" : "gray-text"}
            onClick={() => setKong("yearly")}
          >
            {lunar?.getYearXunKongExact()}
          </td>
          <td
            className={kong === "monthly" ? "gray-text active" : "gray-text"}
            onClick={() => setKong("monthly")}
          >
            {lunar?.getMonthXunKongExact()}
          </td>
          <td
            className={kong === "daily" ? "gray-text active" : "gray-text"}
            onClick={() => setKong("daily")}
          >
            {lunar?.getDayXunKong()}
          </td>
          <td
            className={kong === "hourly" ? "gray-text active" : "gray-text"}
            onClick={() => setKong("hourly")}
          >
            {lunar?.getTimeXunKong()}
          </td>
        </tr>
        <tr>
          <td
            className={
              kong === "yearly"
                ? "gray-text nostyle active"
                : "gray-text nostyle"
            }
            onClick={() => setKong("yearly")}
          >
            {KONGWANG[lunar?.getYearXunKongExact()]}
          </td>
          <td
            className={kong === "monthly" ? "gray-text active" : "gray-text"}
            onClick={() => setKong("monthly")}
          >
            {KONGWANG[lunar?.getMonthXunKongExact()]}
          </td>
          <td
            className={kong === "daily" ? "gray-text active" : "gray-text"}
            onClick={() => setKong("daily")}
          >
            {KONGWANG[lunar?.getDayXunKong()]}
          </td>
          <td
            className={kong === "hourly" ? "gray-text active" : "gray-text"}
            onClick={() => setKong("hourly")}
          >
            {KONGWANG[lunar?.getTimeXunKong()]}
          </td>
        </tr>
      </tbody>
    </table>
  );
};
