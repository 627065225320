import "./App.css";
import { MeiHua } from "./components/MeiHua";
import { MeiHuaForm } from "./components/MeiHuaForm";
import { useState } from "react";
import { useRecoilState } from "recoil";
import { $bottomAtom, $numbersAtom, $thingAtom, $topAtom, $variableAtom } from "./utils/state";

function App() {
  const [init, setInit] = useState(false);
  const [, setNumbers] = useRecoilState($numbersAtom);
  const [, setThing] = useRecoilState($thingAtom);
  const [, setTop] = useRecoilState($topAtom);
  const [, setBottom] = useRecoilState($bottomAtom);
  const [, setVariable] = useRecoilState($variableAtom);

  return (
    <div className="App">
      {!init ? (
        <div className="App-header">
          <MeiHuaForm onSubmit={() => setInit(true)} />
        </div>
      ) : (
        <div className="meihua-container">
          <MeiHua reset={() =>{
            setInit(false);
            setThing(undefined);
            setNumbers(undefined);
            setVariable(undefined);
            setBottom(undefined);
            setTop(undefined);
          }} />
        </div>
      )}
    </div>
  );
}

export default App;
