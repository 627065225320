import { atom } from "recoil";

export const $numbersAtom = atom({
  key: "numbersAtom",
  default: undefined,
});

export const $topAtom = atom({
  key: "topAtom",
  default: undefined,
});

export const $bottomAtom = atom({
  key: "bottomAtom",
  default: undefined,
});

export const $variableAtom = atom({
  key: "variableAtom",
  default: undefined,
});

export const $timeAtom = atom({
  key: "timeAtom",
  default: undefined,
});

export const $thingAtom = atom({
  key: "thingAtom",
  default: undefined,
});

export const $kuAtom = atom({
  key: "kuAtom",
  default: 'monthly',
});

export const $kongAtom = atom({
  key: "kongAtom",
  default: 'daily',
});

export const $wangShuaiAtom = atom({
  key: "wangShuaiAtom",
  default: 'monthly',
});