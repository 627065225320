export const SHENGKE_NAME = ["生", "克", "冲", "合"];
export const GUA = [
  undefined,
  {
    name: "乾",
    icon: "☰",
    yao: [1, 1, 1],
    gan: [],
    zhi: ["戌", "亥"],
    dir: "西北",
    wuxing: "金",
    color: "gold",
    shengke: [["坎"], ["震", "巽"], ["乾"], ["兑"]],
    cool: "丑",
    kong: "戌亥",
  },
  {
    name: "兑",
    icon: "☱",
    yao: [0, 1, 1],
    gan: ["庚", "辛"],
    zhi: ["酉"],
    dir: "西",
    wuxing: "金",
    color: "gold",
    shengke: [["坎"], ["震", "巽"], ["兑"], ["乾"]],
    cool: "丑",
    kong: "申酉",
  },
  {
    name: "离",
    icon: "☲",
    yao: [1, 0, 1],
    gan: ["丙", "丁"],
    zhi: ["午"],
    dir: "南",
    wuxing: "火",
    color: "#D92E18",
    shengke: [["艮", "坤"], ["乾", "兑"], ["离"], []],
    cool: "戌",
    kong: "午未",
  },
  {
    name: "震",
    icon: "☳",
    yao: [0, 0, 1],
    gan: ["甲", "乙"],
    zhi: ["卯"],
    dir: "东",
    wuxing: "木",
    color: "forestgreen",
    shengke: [["离"], ["艮", "坤"], ["震"], ["巽"]],
    cool: "未",
    kong: "寅卯",
  },
  {
    name: "巽",
    icon: "☴",
    yao: [1, 1, 0],
    gan: [],
    zhi: ["辰", "巳"],
    dir: "东",
    wuxing: "木",
    color: "forestgreen",
    shengke: [["离"], ["艮", "坤"], ["巽"], ["震"]],
    cool: "未",
    kong: "辰巳",
  },
  {
    name: "坎",
    icon: "☵",
    yao: [0, 1, 0],
    gan: ["壬", "癸"],
    zhi: ["子"],
    dir: "东",
    wuxing: "水",
    color: "black",
    shengke: [["震", "巽"], ["离"], ["坎"], []],
    cool: "辰",
    kong: "子丑",
  },
  {
    name: "艮",
    icon: "☶",
    yao: [1, 0, 0],
    gan: [],
    zhi: ["丑", "寅"],
    dir: "东北",
    wuxing: "土",
    color: "saddlebrown",
    shengke: [["乾", "兑"], ["坎"], ["艮", "坤"], []],
    kong: "子丑",
  },
  {
    name: "坤",
    icon: "☷",
    yao: [0, 0, 0],
    gan: [],
    zhi: ["未", "申"],
    dir: "西南",
    wuxing: "土",
    color: "saddlebrown",
    shengke: [["乾", "兑"], ["坎"], ["艮", "坤"], []],
    kong: "午未",
  },
];

export const SHENSHA = {
  日禄: {
    甲: "寅",
    乙: "卯",
    丙: "巳",
    丁: "午",
    戊: "巳",
    己: "午",
    庚: "申",
    辛: "酉",
    壬: "亥",
    癸: "子",
  },
  贵人: {
    甲: ["丑", "未"],
    乙: ["子", "申"],
    丙: ["亥", "酉"],
    丁: ["亥", "酉"],
    戊: ["丑", "未"],
    己: ["子", "申"],
    庚: ["丑", "未"],
    辛: ["午", "寅"],
    壬: ["巳", "卯"],
    癸: ["巳", "卯"],
  },
  桃花: {
    子: "酉",
    丑: "午",
    寅: "卯",
    卯: "子",
    辰: "酉",
    巳: "午",
    午: "卯",
    未: "子",
    申: "酉",
    酉: "午",
    戌: "卯",
    亥: "子",
  },
  驿马: {
    子: "寅",
    丑: "亥",
    寅: "申",
    卯: "巳",
    辰: "寅",
    巳: "亥",
    午: "申",
    未: "巳",
    申: "寅",
    酉: "亥",
    戌: "申",
    亥: "巳",
  },
};

export const BAGUA = {
  111111: "乾为天",
  0: "坤为地",
  10001: "水雷屯",
  100010: "山水蒙",
  10111: "水天需",
  111010: "天水讼",
  10: "地水师",
  10000: "水地比",
  110111: "风天小畜",
  111011: "天泽履",
  111: "地天泰",
  111000: "天地否",
  111101: "天火同人",
  101111: "火天大有",
  100: "地山谦",
  1000: "雷地豫",
  11001: "泽雷随",
  100110: "山风蛊",
  11: "地泽临",
  110000: "风地观",
  101001: "火雷噬嗑",
  100101: "山火贲",
  100000: "山地剥",
  1: "地雷复",
  111001: "天雷无妄",
  100111: "山天大畜",
  100001: "山雷颐",
  11110: "泽风大过",
  10010: "坎为水",
  101101: "离为火",
  11100: "泽山咸",
  1110: "雷风恒",
  111100: "天山遁",
  1111: "雷天大壮",
  101000: "火地晋",
  101: "地火明夷",
  110101: "风火家人",
  101011: "火泽睽",
  10100: "水山蹇",
  1010: "雷水解",
  100011: "山泽损",
  110001: "风雷益",
  11111: "泽天夬",
  111110: "天风姤",
  11000: "泽地萃",
  110: "地风升",
  11010: "泽水困",
  10110: "水风井",
  11101: "泽火革",
  101110: "火风鼎",
  1001: "震为雷",
  100100: "艮为山",
  110100: "风山渐",
  1011: "雷泽归妹",
  1101: "雷火丰",
  101100: "火山旅",
  110110: "巽为风",
  11011: "兑为泽",
  110010: "风水涣",
  10011: "水泽节",
  110011: "风泽中孚",
  1100: "雷山小过",
  10101: "水火既济",
  101010: "火水未济",
};

export const KONGWANG = {
  戌亥: "☰乾",
  申酉: "☱兑",
  午未: "☲离",
  辰巳: "☴巽",
  寅卯: "☳震",
  子丑: "☵坎 ☶艮",
};

export const WANGSHUA_NAME = ["旺", "相", "休", "囚", "死"];

export const WANGSHUA = [
  ["木", "火", "水", "金", "土"],
  ["木", "火", "水", "金", "土"],
  ["土", "金", "火", "木", "水"],
  ["火", "土", "木", "水", "金"],
  ["火", "土", "木", "水", "金"],
  ["土", "金", "火", "木", "水"],
  ["金", "水", "土", "火", "木"],
  ["金", "水", "土", "火", "木"],
  ["土", "金", "火", "木", "水"],
  ["水", "木", "金", "土", "火"],
  ["水", "木", "金", "土", "火"],
  ["土", "金", "火", "木", "水"],
];

export const ZHI = [
  "寅",
  "卯",
  "辰",
  "巳",
  "午",
  "未",
  "申",
  "酉",
  "戌",
  "亥",
  "子",
  "丑",
];
