import {
  Button,
  DatePicker,
  Input,
  Radio,
  Space,
  Tabs,
  TimePicker,
} from "antd";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import {
  $bottomAtom,
  $numbersAtom,
  $thingAtom,
  $timeAtom,
  $topAtom,
  $variableAtom,
} from "../utils/state";
import { GUA } from "../utils/CONSTANTS";

export const MeiHuaForm = ({ onSubmit }) => {
  const [top, setTop] = useRecoilState($topAtom);
  const [bottom, setBottom] = useRecoilState($bottomAtom);
  const [variable, setVariable] = useRecoilState($variableAtom);
  const [time, setTime] = useRecoilState($timeAtom);
  const [thing, setThing] = useRecoilState($thingAtom);
  const [numbers, setNumbers] = useRecoilState($numbersAtom);
  const [activeKey, setActiveKey] = useState("1");
  const [err, setErr] = useState();
  const [showDonate, setShowDonate] = useState(false);

  useEffect(() => {
    if (!numbers) {
      setTop(undefined);
      setBottom(undefined);
      setVariable(undefined);
      setTime(undefined);
      return;
    }

    const arr = numbers.split(/[^a-zA-Z0-9]+/g).filter((item) => item !== "");

    if (arr.some((item) => Number.isNaN(+item))) {
      setErr("报数里有非数字");

      return;
    } else {
      setErr(undefined);
    }

    const middle = Math.floor(arr.length / 2);

    let t = 0;
    let b = 0;
    let v = 0;

    if (arr.length === 1) {
      t = arr[0] % 8;
      b = arr[0] % 8;
      v = arr[0] % 6;
    } else {
      t = arr.slice(0, middle).reduce((prev, next) => +prev + +next, 0) % 8;
      b = arr.slice(middle).reduce((prev, next) => +prev + +next, 0) % 8;
      v = arr.reduce((prev, next) => +prev + +next, 0) % 6;
    }

    setTop(t === 0 ? 8 : t);
    setBottom(b === 0 ? 8 : b);
    setVariable(v === 0 ? 6 : v);
    setTime(new Date());
  }, [numbers, err]);

  const items = [
    {
      key: "1",
      label: "报数起卦",
      children: (
        <>
          <Input
            placeholder="请输入报数，以空格分割"
            value={numbers}
            status={err ? "error" : undefined}
            onChange={(e) => setNumbers(e.target.value)}
          />
          {err ? (
            <span className="form-err-txt">{err}</span>
          ) : (
            <span className="form-help-txt">
              输入正确报数以后排盘按钮将会被启用
            </span>
          )}
        </>
      ),
    },
    {
      key: "2",
      label: "手动起卦",
      children: (
        <Space direction="vertical">
          <div>
            上卦：
            <Radio.Group
              options={GUA.slice(1).map((gua, idx) => ({
                label: `${gua.icon}${gua.name}`,
                value: idx + 1,
              }))}
              onChange={(e) => {
                setTop(e.target.value);
              }}
              value={top}
              optionType="button"
              buttonStyle="solid"
            />
          </div>
          <div>
            下卦：
            <Radio.Group
              options={GUA.slice(1).map((gua, idx) => ({
                label: `${gua.icon}${gua.name}`,
                value: idx + 1,
              }))}
              onChange={(e) => {
                setBottom(e.target.value);
              }}
              value={bottom}
              optionType="button"
              buttonStyle="solid"
            />
          </div>
          <Space>
            <div>
              变爻：
              <Button
                onClick={() => {
                  const time = new Date();
                  const v = (time.getHours() + time.getMinutes()) % 6;

                  setTime(time);
                  setVariable(v === 0 ? 6 : v);
                }}
              >
                时间取动爻
              </Button>
            </div>
            <Radio.Group
              options={[
                {
                  label: "一爻",
                  value: 1,
                },
                {
                  label: "二爻",
                  value: 2,
                },
                {
                  label: "三爻",
                  value: 3,
                },
                {
                  label: "四爻",
                  value: 4,
                },
                {
                  label: "五爻",
                  value: 5,
                },
                {
                  label: "六爻",
                  value: 6,
                },
              ]}
              onChange={(e) => {
                setTime(new Date());
                setVariable(e.target.value);
              }}
              value={variable}
              optionType="button"
              buttonStyle="solid"
            />
            <Input
              value={time?.toLocaleString()}
              onChange={(e) => {
                if (
                  new Date(e.target.value).toString().toLocaleLowerCase() !==
                  "invalid date"
                ) {
                  return setTime(new Date(e.target.value));
                }

                setTime(e.target.value);
              }}
            />
          </Space>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Space direction="vertical" style={{ width: "100%" }}>
        <h3 style={{ textAlign: "center" }}>梅花易数排盘</h3>
        <div>
          请输入事由：
          <Input
            value={thing}
            onChange={(e) => setThing(e.target.value)}
            placeholder="请输入闻讯的是由(可选)"
          />
        </div>
        <Tabs activeKey={activeKey} onChange={setActiveKey} items={items} />
        <Space>
          <Button
            type="primary"
            block
            disabled={
              !top ||
              !bottom ||
              !variable ||
              (new Date(time).toString().toLocaleLowerCase() ===
                "invalid date" &&
                activeKey === "2")
            }
            onClick={onSubmit}
          >
            起卦
          </Button>
          <Button danger onClick={() => setShowDonate(true)}>捐赠</Button>
        </Space>
      </Space>
      <div className="donate" onClick={() => setShowDonate(false)} style={{display: showDonate ? 'flex' : 'none'}}>
        <p>点击关闭</p>
        <Space direction="vertical">
          <img
            src="/alipay.png"
            alt="alipay"
            width="300"
          />
          <img
            src="/wcpay.png"
            alt="wechat pay"
            width="300"
          />
        </Space>
      </div>
    </>
  );
};
